// Dashboards.tsx
import React, { useState, useEffect, useCallback } from 'react';
import { useData, FlatFileCsvData} from '../data/DataContext';
//import { useStatus } from '../data/StatusContext';
import { MainHistogram } from '../components/structure/MainHistogram';
import { MainMap } from '../components/maps/MainMap';
import debounce from 'lodash.debounce';
import ChartPage from '../components/structure/ChartPage';
import MenuBar from '../components/structure/MenuBar';
import { Selector } from '../components/inputs/Selector';
import { on } from 'events';
import { useGlobalActiveIndicator, useGlobalBivariateIndicator, useGlobalActiveGeography } from '../data/StatusStore';
import { useGlobalSwarmCalibration, onSetSwarmCalibration } from '../data/GlobalStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import './views.scss';
import { BivariateLegend } from '../components/meta/BivariateLegend';
import _ from 'lodash';
import NumberPicker from "react-widgets/NumberPicker";
import "react-widgets/scss/styles.scss";
import { Combobox } from 'react-widgets/cjs';

export interface DashboardsProps {
   indicatorOptions: any | null,
   groupedModules: any | null,
   unGroupedModules: any | null,
   allData: any | null,
   handleChildReady: Function,
   activeGeography: string,
   moduleIdIncrement: number,
}

const Dashboards: React.FC<DashboardsProps> = ({   
    indicatorOptions,
    groupedModules,
    unGroupedModules,
    allData,
    handleChildReady,
    activeGeography,
    moduleIdIncrement,
}) => {
  const { 
    dataActive, dataTract, dataNTA, dataPUMA, dataNYC, dataCD, dataBoro, isLoadingSorter,
    isLoadingTract, isLoadingNTA, isLoadingPUMA, isLoadingNYC, isLoadingCD, isLoadingBoro, dataSorter,
    error } = useData();

    //const [indicatorOptions, setIndicatorOptions] = useState<any | null>(null);
    //const [groupedModules, setGroupedModules] = useState<any | null>(null);
    //const [allData, setAllData] = useState<any | null>(null);
    
    const [mapType, setMapType] = useState<string>("choropleth");

    const activeIndicator = useGlobalActiveIndicator();
    const bivariateIndicator = useGlobalBivariateIndicator();
    //const activeGeography = useGlobalActiveGeography();

    const swarmCalibration = useGlobalSwarmCalibration();

    useEffect(() => {
        console.log("F092524 indicatorOptions", indicatorOptions);
    }, [indicatorOptions]);

    useEffect(() => {
        console.log("F092524 groupedModules", groupedModules);
    }, [groupedModules]);

    useEffect(() => {
        console.log("activeGeography", activeGeography);
    }, [activeGeography]);

    useEffect(() => {
        console.log("allData", allData);
    }, [allData]);

    if (isLoadingTract || isLoadingNTA || isLoadingPUMA || isLoadingNYC || isLoadingCD || isLoadingBoro || !allData) {
        return <div className='main-container-loading'>
                <div className="loading-data">
                    Loading data.
                    <div className="loading-spinner">
                        <FontAwesomeIcon icon={faSpinner} spin />
                    </div>
                </div>
            </div>
    }
    if (error) return <p>Error loading map data: {error}</p>;
    //console.log("A051024 swarmCalibration", swarmCalibration)

    //console.log("092924 indicatorOptions", indicatorOptions);
    //console.log("092924 groupedModules", groupedModules);
    return (
        <div className='main-container'>
            <div className='map-hist-container' id="mapHistContainer">
                {/*<div
                    style={{
                        position: "absolute",
                        top: "33px",
                        //left: "calc(40% - 165px)",
                        right: "calc(100% - 36.25%)",
                        zIndex: "1000",
                        display: "grid",
                        fontSize: "10px",
                        opacity: "0.5",
                    }}>
                    <Selector 
                        options={[
                            {"label":"Choropleth", "value":"choropleth"},
                            {"label":"Bivariate Standard", "value":"bivariate - standard"},
                            {"label":"Bivariate Inverted", "value":"bivariate - inverted"} 
                        ]}
                        onChange={(e: any) => {
                            console.log("e", e);
                            setMapType(e)
                        }}
                        placeholder={"Choropleth"} 
                    />
                    <div     
                        style={{
                            width:"111px",
                            backgroundColor: "white",
                            float:"right",
                        }}>
                        <div
                        style={{
                            width:"111px",
                            fontSize:"8px",
                            backgroundColor: "white",
                        }}
                        >Collision Threshold (%)</div>
                        <NumberPicker
                            style={{
                                width:"111px",
                                fontSize:"10px",
                            }}
                            //defaultValue={0.15}
                            value={swarmCalibration[activeGeography].collisionDiameter}
                            step={activeGeography == "Tract" ? 0.001 : 0.1}
                            onChange={(e) => {
                                console.log("A051024 e", e )
                                onSetSwarmCalibration({
                                    ...swarmCalibration,
                                    [activeGeography]: {
                                        ...swarmCalibration[activeGeography],
                                        collisionDiameter: e
                                    }
                                });
                            }}
                        /> 
                        <div
                            style={{
                                width:"111px",
                                fontSize:"8px",
                                backgroundColor: "white",
                            }}
                        >Radius</div>
                        <NumberPicker
                            style={{
                                width:"111px",
                                fontSize:"10px",
                            }}
                            //defaultValue={0.75}
                            value={swarmCalibration[activeGeography].radius}
                            step={activeGeography == "Tract" ? 0.05 : 0.5}
                            onChange={(e) => {
                                console.log("A051024 e", e )
                                onSetSwarmCalibration({
                                    ...swarmCalibration,
                                    [activeGeography]: {
                                        ...swarmCalibration[activeGeography],
                                        radius: e
                                    }
                                });
                            }}
                        /> 
                        <div
                            style={{
                                width:"111px",
                                fontSize:"8px",
                                backgroundColor: "white",
                            }}
                        >Stack Count</div>
                        <NumberPicker
                            style={{
                                width:"111px",
                                fontSize:"10px",
                            }}
                            //defaultValue={0.75}
                            value={swarmCalibration[activeGeography].stackCount}
                            step={1}
                            onChange={(e) => {
                                console.log("A051024 e", e )
                                onSetSwarmCalibration({
                                    ...swarmCalibration,
                                    [activeGeography]: {
                                        ...swarmCalibration[activeGeography],
                                        stackCount: e
                                    }
                                });
                            }}
                        /> 
                        <div
                            style={{
                                width:"111px",
                                fontSize:"8px",
                                backgroundColor: "white",
                            }}
                        >Test Each Point</div>
                        <Combobox
                            defaultValue="FALSE"
                            data={["TRUE", "FALSE"]}
                            onChange={(e) => {
                                console.log("A051024 e", e )
                                onSetSwarmCalibration({
                                    ...swarmCalibration,
                                    [activeGeography]: {
                                        ...swarmCalibration[activeGeography],
                                        testEachPoint: e
                                    }
                                });
                            }}
                        />
                    </div>
                </div>*/}
                <MainMap 
                    keyIndexDataArray={dataActive?.keyIndexDataArray}
                    dataArray={dataActive?.dataArray}
                    dataJSON={dataActive?.dataJSON}
                    dataMeta={dataActive?.meta}
                    activeGeography={activeGeography}
                    chartId={"main-map-stat-explorer"}
                    height={'calc(100% - 350px)'}
                    width={'100%'}
                    mapType={mapType}
                    unGroupedModules={unGroupedModules}
                />
                <div style={{position:"relative", height: "350px"}} 
                    className='main-histogram-container'
                    id='mainHistogramContainer'
                > 
                    {mapType === "choropleth"
                        ? <MainHistogram  
                                dataGeo={dataActive}
                                histMax={allData[activeGeography]?.meta?.max_value}
                                histNA={allData[activeGeography]?.meta?.na_count} 
                                histLength={allData[activeGeography]?.meta?.length} 
                                chartId={"main-hist"}
                                groupedModules={groupedModules}
                                unGroupedModules={unGroupedModules}
                            />
                        : <BivariateLegend
                                dataGeo={dataActive}
                                histMax={allData[activeGeography]?.meta?.max_value}
                                histNA={allData[activeGeography]?.meta?.na_count} 
                                histLength={allData[activeGeography]?.meta?.length} 
                                chartId={"main-hist"}
                                height={'180'}
                                width={'calc(100% - 20px)'}
                                mapType={mapType}
                                activeIndicator={activeIndicator}
                                bivariateIndicator={bivariateIndicator}
                            />
                    }
                </div>
            </div>
            <div className='featured-view-container'>
                <MenuBar
                    key={`menu-bar`}
                    indicatorOptions={indicatorOptions}
                    groupedModules={groupedModules}
                    allData={allData}
                    chartId={"main-hist"}
                    displaySubCategory={false}
                />
                {/*<div
                    id = 'in-view-root'
                    style={{
                        width:'100%',
                        height:'10px',
                        backgroundColor:'red',
                        zIndex:1000,
                        position:'relative',
                        top:'50%',
                        //left:'0',
                        //transform:'translateY(-50%)',
                    }}
                ></div>*/}
                {/*<Selector 
                    options={[
                        {"label":"Tract", "value":"Tract"},
                        {"label":"PUMA", "value":"PUMA"},
                        {"label":"CD", "value":"CD"},
                        {"label":"Boro", "value":"Boro"},
                        {"label":"NYC", "value":"NYC"} 
                    ]}
                    onChange={handleGeographyChange}
                    placeholder={activeGeography} 
                    />
                <Selector 
                    options={indicatorOptions ? indicatorOptions : []}
                    onChange={handleIndicatorChange}
                    placeholder={activeIndicator} 
                />*/}
                {groupedModules //indicatorOptions 
                ?   <ChartPage
                        key={`chart-list-${activeGeography}`}
                        indicatorOptions={indicatorOptions}
                        groupedModules={groupedModules}
                        allData={allData}
                        chartId={`id-chart-list-${activeGeography}`}
                        handleChildReady={handleChildReady}
                        moduleIdIncrement={moduleIdIncrement}
                    />
                : null}
            </div>
        </div>
    );
};

export default Dashboards;
