// Comparisons.tsx
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useData, FlatFileCsvData} from '../data/DataContext';
//import { useStatus } from '../data/StatusContext';
import { MainHistogram } from '../components/structure/MainHistogram';
import CorrelationCard from '../components/charts/CorrelationCard';
import ScatterPlot from '../components/charts/ScatterPlot';
import { MainMap } from '../components/maps/MainMap';
import IndicatorDropdown from '../components/inputs/IndicatorDropdown';
//import debounce from 'lodash.debounce';
import { Histogram } from '../components/charts/Histogram';
//import ChartList from '../components/structure/ChartList';
//import MenuBar from '../components/structure/MenuBar';
import { Selector } from '../components/inputs/Selector';
//import { on } from 'events';
import { useGlobalActiveIndicator, useGlobalBivariateIndicator, useGlobalActiveGeography, useGlobalSelectedId } from '../data/StatusStore';
import { useGlobalSwarmCalibration, onSetSwarmCalibration } from '../data/GlobalStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import './views.scss';
import { BivariateLegend } from '../components/meta/BivariateLegend';
import _ from 'lodash';
import NumberPicker from "react-widgets/NumberPicker";
import "react-widgets/scss/styles.scss";
import { Combobox } from 'react-widgets/cjs';

export interface ComparisonsProps {
    indicatorOptions: any | null,
    groupedModules: any | null,
    unGroupedModules: any | null,
    allData: any | null,
    handleChildReady: Function,
    activeGeography: string
}

const Comparisons: React.FC<ComparisonsProps> = ({  
    indicatorOptions,
    groupedModules,
    unGroupedModules,
    allData,
    handleChildReady,
    activeGeography
}) => {
  const { 
    dataActive, dataTract, dataNTA, dataPUMA, dataNYC, dataCD, dataBoro, isLoadingSorter,
    isLoadingTract, isLoadingNTA, isLoadingPUMA, isLoadingNYC, isLoadingCD, isLoadingBoro, dataSorter,
    error } = useData();

    //const [indicatorOptions, setIndicatorOptions] = useState<any | null>(null);
    //const [groupedModules, setGroupedModules] = useState<any | null>(null);
    //const [allData, setAllData] = useState<any | null>(null);
    
    const [mapType, setMapType] = useState<string>("bivariate - inverted");

    const activeIndicator = useGlobalActiveIndicator();
    const bivariateIndicator = useGlobalBivariateIndicator();
    //const activeGeography = useGlobalActiveGeography();
    //const selectedId = useGlobalSelectedId();
    const swarmCalibration = useGlobalSwarmCalibration();

    const ref = React.useRef<HTMLDivElement>(null);
    
    const height = '200'
    const width = 'calc(100% - 0px)';

    useEffect(() => {
        console.log("indicatorOptions", indicatorOptions);
    }, [indicatorOptions]);

    useEffect(() => {
        console.log("groupedModules", groupedModules);
    }, [groupedModules]);

    useEffect(() => {
        console.log("activeGeography", activeGeography);
    }, [activeGeography]);

    useEffect(() => {
        console.log("allData", allData);
    }, [allData]);

    const memoizedCorrelationCard = useMemo(() => (
        console.log("C112824 allData", allData),
        console.log("C112824 activeGeography", activeGeography),
        console.log("C112824 activeIndicator", activeIndicator),
        console.log("C112824 allData[activeGeography]", allData?.[activeGeography]),

        allData && allData[activeGeography] && activeGeography && activeIndicator && bivariateIndicator && dataActive ? (
            <div
                style={{
                    height: "100%",
                    width: '300px',
                    paddingTop: '20px',
                    borderTop: '1px solid #ccc',
                    backgroundColor: "white",
                    overflowY: "hidden",
                    overflowX: "hidden",
                }}>
                <CorrelationCard
                    dataArrayX={allData[activeGeography]?.keyIndexDataArray[activeIndicator]}
                    dataArrayY={allData[activeGeography]?.keyIndexDataArray[bivariateIndicator]}
                    xLabel={dataActive?.meta.DISPLAY_NAME[activeIndicator]}
                    yLabel={dataActive?.meta.DISPLAY_NAME[bivariateIndicator]}
                />
            </div>
        ) : null
    ), [allData, activeGeography, activeIndicator, bivariateIndicator, dataActive]);

    const memoizedScatter = useMemo(() => (
        console.log("A070724 allData", allData),
        console.log("A070724 activeIndicator", activeIndicator),
        console.log("A070724 activeGeography", activeGeography),
        //console.log("A070724 allData[activeGeography]", allData[activeGeography]),
        allData 
            ? <>
                <ScatterPlot 
                    key={`scatter_${activeIndicator}_${bivariateIndicator}_compare`} 
                    dataArrayX={allData[activeGeography]?.keyIndexDataArray[activeIndicator]}
                    dataArrayY={allData[activeGeography]?.keyIndexDataArray[bivariateIndicator]}
                    dataMeta={allData[activeGeography]?.meta}
                    histXMax={allData[activeGeography]?.meta?.max_value[activeIndicator]}
                    histXMin={allData[activeGeography]?.meta?.min_value[activeIndicator]}
                    histXNA={allData[activeGeography]?.meta?.na_count[activeIndicator]} 
                    histXLength={allData[activeGeography]?.meta?.length[activeIndicator]} 
                    histYMax={allData[activeGeography]?.meta?.max_value[bivariateIndicator]}
                    histYMin={allData[activeGeography]?.meta?.min_value[bivariateIndicator]}
                    histYNA={allData[activeGeography]?.meta?.na_count[bivariateIndicator]} 
                    histYLength={allData[activeGeography]?.meta?.length[bivariateIndicator]} 
                    allData={allData[activeGeography]} 
                    chartId={bivariateIndicator}
                    height={'700'}
                    width={'700'}
                    handleChildReady={handleChildReady}
                    activeIndicator={activeIndicator}
                    bivariateIndicator={bivariateIndicator}
                    mapType={mapType}
                    radius={swarmCalibration?.ScatterPlot?.radius}
                />
            </>
            : null
      ), [activeIndicator, bivariateIndicator,  allData, activeGeography, height, width, mapType, swarmCalibration]); 

    if (isLoadingTract || isLoadingNTA || isLoadingPUMA || isLoadingNYC || isLoadingCD || isLoadingBoro || !allData) {
        return <div className='main-container-loading'>
                <div className="loading-data">
                    Loading data.
                    <div className="loading-spinner">
                        <FontAwesomeIcon icon={faSpinner} spin />
                    </div>
                </div>
            </div>
    }

    if (error) return <p>Error loading map data: {error}</p>;
    console.log("A051024 swarmCalibration", swarmCalibration)
    return (
        <div className='main-container'>
            <div className='map-hist-container' id="mapHistContainer">
                {/*<div
                    style={{
                        position: "absolute",
                        top: "33px",
                        //left: "calc(40% - 165px)",
                        right: "calc(100% - 36.25%)",
                        zIndex: "1000",
                        display: "grid",
                        fontSize: "10px",
                        opacity: "0.5",
                    }}>
                    <Selector 
                        options={[
                            {"label":"Choropleth", "value":"choropleth"},
                            {"label":"Bivariate Standard", "value":"bivariate - standard"},
                            {"label":"Bivariate Inverted", "value":"bivariate - inverted"} 
                        ]}
                        onChange={(e: any) => {
                            console.log("e", e);
                            setMapType(e)
                        }}
                        placeholder={"Bivariate Inverted"} 
                    />
                    <div     
                        style={{
                            width:"111px",
                            backgroundColor: "white",
                            float:"right",
                        }}>
                        <div
                            style={{
                                width:"111px",
                                fontSize:"8px",
                                backgroundColor: "white",
                            }}
                        >Scatterplot Radius</div>
                        <NumberPicker
                            style={{
                                width:"111px",
                                fontSize:"10px",
                            }}
                            //defaultValue={0.75}
                            value={swarmCalibration?.ScatterPlot?.radius}
                            step={1}
                            onChange={(e) => {
                                console.log("A051024 e", e )
                                onSetSwarmCalibration({
                                    ...swarmCalibration,
                                    ["ScatterPlot"]: {
                                        radius:e,
                                    }
                                });
                            }}
                        /> 
                    </div>
                </div>*/}
                <MainMap 
                    keyIndexDataArray={dataActive?.keyIndexDataArray}
                    dataArray={dataActive?.dataArray}
                    dataJSON={dataActive?.dataJSON}
                    dataMeta={dataActive?.meta}
                    activeGeography={activeGeography}
                    chartId={"main-map-comparisons"}
                    height={'calc(100% - 350px)'}
                    width={'100%'}
                    mapType={mapType}
                    unGroupedModules={unGroupedModules}
                />
                <div style={{position:"relative", height: "350px"}} 
                    className='main-histogram-container'
                    id='mainHistogramContainer'
                > 
                    {mapType === "choropleth"
                        ? <MainHistogram  
                                dataGeo={dataActive}
                                histMax={allData[activeGeography]?.meta?.max_value}
                                histNA={allData[activeGeography]?.meta?.na_count} 
                                histLength={allData[activeGeography]?.meta?.length} 
                                chartId={"main-hist"}
                                groupedModules={groupedModules}
                            />
                        : <BivariateLegend
                                dataGeo={dataActive}
                                histMax={allData[activeGeography]?.meta?.max_value}
                                histNA={allData[activeGeography]?.meta?.na_count} 
                                histLength={allData[activeGeography]?.meta?.length} 
                                chartId={"main-hist"}
                                height={'180'}
                                width={'calc(100% - 20px)'}
                                mapType={mapType}
                                activeIndicator={activeIndicator}
                                bivariateIndicator={bivariateIndicator}
                            />
                    }
                </div>
            </div>
            <div className='featured-view-container'>
                {/*<MenuBar
                    key={`menu-bar`}
                    indicatorOptions={indicatorOptions}
                    groupedModules={groupedModules}
                    allData={allData}
                    chartId={"main-hist"}
                />
                <Selector 
                    options={[
                        {"label":"Tract", "value":"Tract"},
                        {"label":"PUMA", "value":"PUMA"},
                        {"label":"CD", "value":"CD"},
                        {"label":"Boro", "value":"Boro"},
                        {"label":"NYC", "value":"NYC"} 
                    ]}
                    onChange={handleGeographyChange}
                    placeholder={activeGeography} 
                    />
                <Selector 
                    options={indicatorOptions ? indicatorOptions : []}
                    onChange={handleIndicatorChange}
                    placeholder={activeIndicator} 
                />
                {indicatorOptions 
                ?   <ChartList
                        key={`chart-list`}
                        indicatorOptions={indicatorOptions}
                        groupedModules={groupedModules}
                        allData={allData}
                        chartId={"main-hist"}
                    />
                : null}*/
                
                }
                <IndicatorDropdown options={allData[activeGeography].meta} />
                <div className='featured-view-container-scatter'
                    style={{
                        //display:"flex",
                    }}>
                    {memoizedCorrelationCard}
                    <div
                    //key={`chart-card-${index}-${key}`}
                    ref={ref}
                    style={{
                        height: "100%", //'200px',
                        width: 'calc(100% - 300px)',
                        paddingTop: '20px',
                        borderTop: '1px solid #ccc',
                        backgroundColor: "white", 
                        overflowY: "hidden", //"scroll", 
                        overflowX: "hidden", 
                    }}>
                    
                    {/* <div
                        style={{
                            fontSize: "10px",
                            lineHeight: "19px",
                            color: "gray",
                            backgroundColor: "white",
                            position:"absolute",
                            top: "1px",
                            height:"19px",
                            borderBottom: "1px solid #e8e4e4",
                            width: "100%",
                            zIndex: "1",
                        }}
                        >{chartIndicatorName}</div>*/
                        

                        memoizedScatter
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Comparisons;
