import React, { FC, useRef, useState, useEffect, useMemo } from 'react';
import { VariableSizeList as List } from 'react-window';
import { ChartCard, ChartCardProps } from './ChartCard'; // Adjust the import path as necessary
import { useInView } from 'react-intersection-observer';
import Colors from '../utilities/Colors'; // Adjust the import path as necessary
import { useGlobalActiveCategory, useGlobalActiveSubCategory, onHoverHistogram, useGlobalHoveredId, useGlobalSelectedId, useGlobalActiveIndicator, 
  useGlobalBivariateIndicator, useGlobalActiveGeography, useCategoryChangeComplete, useGlobalModuleId } from '../../data/StatusStore'; // Adjust the import path as necessary
import { onSetActiveIndicator, onSetBivariateIndicator, onSetActiveGeography, onSetSelectedId, onSetActiveSubCategory, onSetActiveCategory, onSetCategoryChangeComplete, onSetDBModuleId } from '../../data/StatusStore';
//import { active, index } from 'd3';
import { debounce } from 'lodash';


interface DataItem {
  id: string;
  value: any;
}

interface ChartIndexProps {
  indicatorOptions: any;
  groupedModules: any;
  allData: any | null;
  chartId: string;
  handleChildReady: Function;
  moduleIdIncrement: number;
}

// New component to observe each ChartCard
const ObservedChartCard: FC<ChartCardProps & {   
    chartIndicator: any;
    chartIndicatorName: string;
    allData: any |null;
    chartId: string;
    height: string;
    width: string;
    index: number;
    options: any;
    activeGeography: string;
    colorCategory: string;
    //item: any;
    //onSetActiveIndicator: (indicator: string) => void;
    //onSetBivariateIndicator: (indicator: string) => void;
    //activeIndicator : string;
    //bivariateIndicator : string;
    _selectedId: string | null;
    style: React.CSSProperties;
    //persistableSelectedId: string | null;
    }> = ({ index, options, chartId, /*item,*/ allData, activeGeography, _selectedId, style, colorCategory, ...props }) => {
  // Scroll Selection is managed by the ref that is created with useInView below and utilized in the return statement.
  // Uncomment these in order to re-engage this functionality. # scroll on div, scrolling selections, indicator selectornull);

  const { ref, inView, entry } = useInView({
    threshold: 0, // Number between 0 and 1 indicating the percentage that should be visible before triggering. Can also be an array of numbers, to create multiple trigger points.
    triggerOnce: false, // Ensure it triggers every time it comes into view
    //root: document.querySelector('#in-view-root'), //divRef.current || null,
    //rootMargin: '0px', // No margin around the root
    //rootMargin: "-50% 0px -50% 0px"
    root: document.querySelector('.list-container'),
    rootMargin: "-50% 0px -50% 0px",
  });

  const categoryChangeComplete = useCategoryChangeComplete();
  const selectedSubCategory = useGlobalActiveSubCategory();
  const selectedCategory = useGlobalActiveCategory();
  const moduleId = useGlobalModuleId();
  
  useEffect(() => {
    
    if (inView) {
      // Do something when the ChartCard is in view
      console.log('C10282 ChartCard in view: props', props);
      console.log('H100424 ChartCard in view: options', options);
      console.log('DD101424 ChartCard moduleId', options.variables[0].sort.moduleId);
      console.log("C101424 categoryChangeComplete", categoryChangeComplete);
      //console.log("DD101424 entry", entry);
      //console.log("DD101424 ref", ref);
      console.log("H100424 selectedCategory", selectedCategory);
      console.log("H100424 selectedSubCategory", selectedSubCategory);
      console.log("H100424 options.category", options.category);
      console.log("H100424 options.subCategory", options.subCategory);
      if (options.variables[0].sort.moduleId !== moduleId) {
        onSetDBModuleId(options.variables[0].sort.moduleId);
      }
      if (options.category !== selectedCategory) {
        onSetCategoryChangeComplete(true);
        onSetActiveCategory(options.category);  
        // Deactivate scrolling
        // onSetActiveIndicator(props.chartIndicator);
      }
      if (options.subCategory !== selectedSubCategory) {
        onSetCategoryChangeComplete(true);
        onSetActiveSubCategory(options.subCategory);
      }
      if (options.subCategory === selectedSubCategory && options.category === selectedCategory) {
        onSetCategoryChangeComplete(false);
      }
      
      //console.log('072724 ChartCard in view: item', item);
      //console.log('072724 ChartCard in view:', props.chartIndicator);
      //if (onSetActiveIndicator) {
        //onSetActiveIndicator(props.chartIndicator);
      //}
    }else{
     // console.log('DD101424 ChartCard moduleId |__', options.variables[0].sort.moduleId);
    }
  }, [inView, props.chartIndicator]);

  console.log("C102824 options",options);
  return (
    <div ref={ref} style={style}>
      <ChartCard 
          key={`chart-card-${index}`}
          index={index}
          chartIndicator={options.variables}
          chartIndicatorName={options.breadcrumb}
          allData={allData}
          chartId={chartId}
          height={'200'}
          width={'calc(100% - 0px)'}
          activeGeography={activeGeography}
          /*onSetActiveIndicator={onSetActiveIndicator}
          onSetBivariateIndicator={onSetBivariateIndicator}
          activeIndicator={activeIndicator}
          bivariateIndicator={bivariateIndicator}*/
          options={options}
          _selectedId={_selectedId}
          //persistableSelectedId={persistableSelectedId}
      />
    </div>
  );
}; 

interface NestedJson {
  [category: string]: {
    [subCategory: string]: {
      [module: string]: {
        [group: string]: {
          [shape: string]: any[];
        };
      };
    };
  };
}

const ChartPage: FC<ChartIndexProps> = ({
  indicatorOptions, groupedModules, allData, chartId, handleChildReady, moduleIdIncrement
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerHeight, setContainerHeight] = useState(0);
  const [persistableSelectedId, setPersistableSelectedId] = useState<string | null>(null);
  //const [previousCategory, setPreviousCategory] = useState<string | null>("");
  const [flattenedModules, setFlattenedModules] = useState<any | null>(null);
  const [heightsModules, setHeightsModules] = useState<any | null>(null);
  const [flattenedModulesKey, setFlattenedModulesKey] = useState(0);
  const [listKey, setListKey] = useState(0);
  const listRef = useRef<List>(null);
  const isScrollingRef = useRef(false);
  const [scrollOffset, setScrollOffset] = useState(0);
  const [scrollIndex, setScrollIndex] = useState(0);

  const activeIndicator = useGlobalActiveIndicator();
  const bivariateIndicator = useGlobalBivariateIndicator();
  const activeGeography = useGlobalActiveGeography();
  const selectedId = useGlobalSelectedId();
  const selectedSubCategory = useGlobalActiveSubCategory();
  const selectedCategory = useGlobalActiveCategory();
  const categoryChangeComplete = useCategoryChangeComplete();
  const moduleId = useGlobalModuleId();

  useEffect(() => {

    //onSetSelectedId("36061000900");
    console.log("101124 indicatorOptions, groupedModules, allData, chartId",indicatorOptions, groupedModules, allData, chartId);

    if (containerRef.current) {
      setContainerHeight(containerRef.current.clientHeight - 81);
    }

    const handleResize = () => {
      if (containerRef.current) {
        setContainerHeight(containerRef.current.clientHeight - 81);
      }
    };
    console.log("101124 calling  handleChildReady ");
    handleChildReady();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // Notify parent when ready
    
  }, []);
  
  useEffect(() => {
    console.log("A100424 activeGeography",activeGeography);
  }, [activeGeography]);
  useEffect(() => {
    console.log("C102024 |______ moduleId",moduleId);
  }, [moduleId]);
  useEffect(() => {
    console.log("C102024 |______ moduleIdIncrement",moduleIdIncrement);
  }, [moduleIdIncrement]);
  useEffect(() => {
    console.log("C101424 scrollOffset",scrollOffset);
    console.log("C101424 heightsModules",heightsModules);
  }, [scrollOffset]);

  useEffect(() => {
    //if (!flattenedModules || flattenedModules.length < 0) {
      console.log("A100424 groupedModules",groupedModules);
      console.log("A062024 selectedCategory",selectedCategory);
      console.log("A062024 selectedSubCategory",selectedSubCategory);
      /*let filteredModules = {
        [selectedCategory]: {
          [selectedSubCategory]: groupedModules[selectedCategory][selectedSubCategory],
        },
      };*/
      let filteredModules = {
        [selectedCategory]: groupedModules[selectedCategory]
      };
      //let filteredModules = {...groupedModules};
      console.log("X102724 filteredModules",filteredModules);
      console.log("Z102724 groupedModules",groupedModules);
      //console.log("X102724 groupedData",groupedData);
      const result = flattenNestedJson({ ...filteredModules });
      //const result = flattenNestedJson({ ...groupedModules });
      console.log("X102724 result",result);
      console.log("D082924 indicatorOptions",indicatorOptions);
      if (result){
        setFlattenedModules(result[0].length !== 0 ? result[0] : flattenedModules ? flattenedModules : []);
        setHeightsModules(Object.keys(result[1]).length !== 0 ? result[1] : heightsModules ? heightsModules : {});
      }
    //}
  }, [groupedModules,  selectedCategory/*, selectedSubCategory*/ ]);

  useEffect(() => {
    console.log("A062024 groupedModules",groupedModules);
    console.log("A062024 selectedCategory",selectedCategory);
    console.log("A062024 selectedSubCategory",selectedSubCategory);
    setFlattenedModulesKey(prevKey => prevKey + 1)
    setListKey(prevKey => prevKey + 1);
  }, [groupedModules/*, selectedCategory, selectedSubCategory*/ ]);

  useEffect(() => {
    console.log("C082924 groupedModules",groupedModules);
  }, [groupedModules]);
  useEffect(() => {
    console.log("C082924 activeGeography",activeGeography);
  }, [activeGeography]);
  useEffect(() => {
    console.log("C082924 flattenedModules",flattenedModules);
    setListKey(prevKey => prevKey + 1); // Update the key to force re-render
  }, [flattenedModules, flattenedModulesKey]);
  useEffect(() => {
    console.log("C082924 listKey",listKey);
  }, [listKey]);
  useEffect(() => {
    console.log("100424 selectedId",selectedId);
    handleChildReady();
    console.log("060524 <--- selectedId",selectedId);
    console.log("060524 <--- persistableSelectedId",persistableSelectedId);
    console.log("060524 <--- activeGeography",activeGeography);
    if (selectedId) {
      setPersistableSelectedId(selectedId);
    }  
  }, [selectedId]);

  function reorderByOrder(data: any): any {
    // Extract first-level keys
    const firstLevelKeys = Object.keys(data);
  
    // Sort the first-level keys based on the Order value found in their nested arrays
    const sortedFirstLevelKeys = firstLevelKeys.sort((a, b) => {
      console.log("JJJ102624 a",a);
      console.log("JJJ102624 b",b);
      console.log("JJJ102624 data",data);
      const orderA = getDeepOrderValue(data[a]);
      const orderB = getDeepOrderValue(data[b]);
      console.log("JJJ102624 orderA",orderA);
      console.log("JJJ102624 orderB",orderB);
      return orderA - orderB;
    });
    console.log("JJ102624 sortedFirstLevelKeys",sortedFirstLevelKeys);
    // Create a new object with sorted first-level keys
    const sortedData: any = {};
    sortedFirstLevelKeys.forEach((key) => {
      if (data[key]){
        sortedData[key] = reorderSecondLevel(data[key]);
      }
    });
  
    return sortedData;
  }
  
  // Helper function to reorder the second-level keys within each first-level key
  function reorderSecondLevel(obj: any): any {
    console.log("D082924 obj",obj);
    const secondLevelKeys = Object.keys(obj);
  
    const sortedSecondLevelKeys = secondLevelKeys.sort((a, b) => {
      const orderA = getDeepOrderValue(obj[a]);
      const orderB = getDeepOrderValue(obj[b]);
      return orderA - orderB;
    });
    console.log("J102624 sortedSecondLevelKeys",sortedSecondLevelKeys);
    const sortedSecondLevelData: any = {};
    sortedSecondLevelKeys.forEach((key) => {
      sortedSecondLevelData[key] = obj[key];
    });
  
    return sortedSecondLevelData;
  }
  
  // Helper function to find the Order value deep within the structure
  function getDeepOrderValue(obj: any): number {
    // Traverse the third-level keys to find the first array and get the Order
    for (const key in obj) {
      if (Array.isArray(obj[key]) && obj[key].length > 0) {
        let ret = obj[key][0]?.Order ?? 0; // Return the Order value, default to 0 if not found
        console.log("JJ102624 obj",obj);
        console.log("JJ102624 key,ret",key,ret);
        return ret; 
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        return getDeepOrderValue(obj[key]); // Recursively look deeper in the object
      }
    }
    return 0; // Default to 0 if no array with Order is found
  }
  
  // Example usage
  const jsonData = {
    "Age at a glance": {
      "Ungrouped": {
        "deviation from average": [
          { VARIABLE_NAME: 'sixtyfive', DISPLAY_NAME: 'Elderly (65+)', Order: 2 },
          // other items...
        ],
      },
    },
    "Birthplace": {
      "pie_birthplace": [
        { VARIABLE_NAME: 'native', DISPLAY_NAME: 'Native Born', Order: 1 },
      ],
    },
    // Other first-level and second-level keys...
  };
  
  //const sortedData = reorderByOrder(jsonData);
  //console.log(sortedData);
  function cleanString(input: string): string {
    let returnable = input
      .toLowerCase() // Convert to lowercase
      .replace(/\s+/g, '-') // Replace spaces with hyphens
      .replace(/[^a-z0-9-]/g, ''); // Remove all non-web-safe characters except hyphens
    console.log("C102724 cleanString", input,">", returnable);
    return returnable;
  }
  
  const flattenNestedJson = (nestedJson: NestedJson): any => {
    const flatArray: any = [];
    const heightsArray: any = {};
    let runningHeight = 0;
    let runningIndex = 0;
    console.log("Z102724 nestedJson",nestedJson);
    Object.keys(nestedJson).forEach(category => {
      console.log("B102824 category", category);
      /*const __object = {
        allDataPresent: true, //allDataPresent,
        variables: [], //transformedDataArray,
        type: "category header", //transformedDataArray[0].type,
        breadcrumb: `${category}`, 
        category: category,
        subCategory: Object.keys(nestedJson[category])[0],
      } 
      //console.log("D082924 getItemSizeWithObject(_object)",getItemSizeWithObject(_object));
      runningHeight = runningHeight + getItemSizeWithObject(__object);
      
      heightsArray[`${cleanString(category)}-header`] = ({
        runningHeight: runningHeight,
        runningIndex: runningIndex,
        moduleId: `${cleanString(category)}-header`,
        breadcrumb: __object.breadcrumb,
        type: __object.type,
      });
      runningIndex = runningIndex + 1;
      flatArray.push(__object);*/
      const subCategories = nestedJson[category];
      console.log("YYY102724 subCategories", subCategories);
      Object.keys(subCategories).forEach(subCategory => {
        const modules = reorderByOrder(subCategories[subCategory]);
        //console.log("YYY102724 subCategory", subCategory);
        //if (subCategory === "American Human Development Index"){
        //  console.log("YYY102724 modules", modules);
        //}
        Object.keys(modules).forEach(module => {
          const groups = modules[module];
          console.log("YY102724 module", module);
          console.log("YY102724 groups", groups);
          //let groups_ = reorderByOrder(groups);
          //console.log("100924 groups_", groups_);
          Object.keys(groups).forEach(group => {
            console.log("YY102724 group", group);
            if (group !== "order"){
              const shapes = groups[group];
              console.log("YY102724 shapes",shapes);
              Object.keys(shapes).forEach(shape => {
                //console.log("100924 shapes", shapes);
                const dataArray = shapes[shape];
                console.log("X102724 dataArray",dataArray);
                console.log("X102724 shape",shape);
                //console.log("072724 activeGeography",activeGeography);
                //console.log("072724 activeGeography.toUpperCase()",activeGeography.toUpperCase());
                let allDataPresent = true;
                
                const transformedDataArray: any = dataArray.map((data: any, index: number) => {
                  //console.log("C100424 data",data);
                  //console.log("C100424 activeGeography",activeGeography);
                  //console.log("C100424 activeGeography.toUpperCase()",activeGeography.toUpperCase());
                  //console.log("C100424 data[activeGeography.toUpperCase()] ",data[activeGeography.toUpperCase()] );
                  //console.log("C100424 data[activeGeography] ",data[activeGeography] );
                  if (data[activeGeography] !== "X") {
                    allDataPresent = false;
                    if (activeGeography == "CD"){
                      if (data["PUMA"] !== "X") {
                        allDataPresent = false;
                      }else{
                        allDataPresent = true;
                      }
                    }
                  }
                  return ({
                    label: data.DISPLAY_NAME,
                    variable: data.VARIABLE_NAME ? data.VARIABLE_NAME : data.variable,
                    type: data.Shape ? data.Shape : "beeswarm",
                    sort: data,
                    index: index,
                    geo: activeGeography,
                    category: category,
                  })
                });
                //if (allDataPresent){
                //console.log("100924 transformedDataArray",transformedDataArray);
                  const _object = {
                    allDataPresent: allDataPresent,
                    variables: transformedDataArray,
                    type: transformedDataArray[0].type,
                    breadcrumb: `${category} > ${subCategory} > ${module} > ${group} > ${shape}`, 
                    category: category,
                    subCategory: subCategory,
                  } 
                  //console.log("D082924 getItemSizeWithObject(_object)",getItemSizeWithObject(_object));
                  runningHeight = runningHeight + getItemSizeWithObject(_object);
                  
                  heightsArray[transformedDataArray[0].sort.moduleId] = ({
                    runningHeight: runningHeight,
                    runningIndex: runningIndex,
                    moduleId: transformedDataArray[0].sort.moduleId,
                    breadcrumb: _object.breadcrumb,
                    type: _object.type,
                  });
                  runningIndex = runningIndex + 1;
                  flatArray.push(_object);
                //}
              });
            }
          });
        });
      });
    });
    console.log("YY102724 flatArray", flatArray);
    console.log("YY102724 heightsArray", heightsArray);
    return [flatArray, heightsArray];
  };

  /*const updateSelectedId = debounce((index: number) => {
    if (flattenedModules && flattenedModules[index]) {
      const item = flattenedModules[index];
      console.log("A060224 item",item);
      console.log("060524 ---> selectedId",selectedId);
      console.log("060524 ---> persistableSelectedId",persistableSelectedId);
      if (item && item.variables && item.variables[0]) {
        if (selectedId){
          console.log("060524 ------------> onSetSelectedId selectedId",selectedId);
          onSetSelectedId(selectedId);
        }else{
          if (persistableSelectedId){
            console.log("060524 ------------> onSetSelectedId persistableSelectedId",persistableSelectedId);
            onSetSelectedId(persistableSelectedId);
          }
        }
      }
    }
  }, 300); // Debounce interval set to 300ms*/

  const scrollToCategory = () => {
    console.log("F101924 selectedCategory",selectedCategory);
    console.log("F101924 selectedSubCategory",selectedSubCategory);
    console.log("F101924 lattenedModules",flattenedModules);
    if (flattenedModules) {
      const index = flattenedModules.findIndex(
        (item: any) => item.category === selectedCategory && item.subCategory === selectedSubCategory
      );
      if (index >= 0 && listRef.current) {
        isScrollingRef.current = true;
        listRef.current.scrollToItem(index, 'start');
        //setTimeout(() => {
        isScrollingRef.current = false;
        console.log("072824 onSetCategoryChangeComplete(false);");
        onSetCategoryChangeComplete(false);
        //}, 500); // Allow some time for the scroll to complete
      }else{
        console.log("072824 onSetCategoryChangeComplete(false);");
        onSetCategoryChangeComplete(false);
      }
    }
  };

  useEffect(() => {
    console.log("072824 categoryChangeComplete",categoryChangeComplete);
    console.log("072824 isScrollingRef.current",isScrollingRef.current);
    if (!isScrollingRef.current && !categoryChangeComplete) {
      scrollToCategory();
    }else{
      console.log("072824 onSetCategoryChangeComplete(false);");
      onSetCategoryChangeComplete(false);
    }
  }, [selectedCategory, selectedSubCategory]);

  const renderRow = useMemo(() => {
    console.log("C100424 indicatorOptions",indicatorOptions)
    console.log("B102824 flattenedModules",flattenedModules)
    //console.log("A041524 allData",allData)
    console.log("C100424 activeGeography",activeGeography)
   
    return ({ index, style }: { index: number; style: React.CSSProperties }) => {
      const item = flattenedModules[index];
      //console.log("N092924 item",item)
      if (!item.allDataPresent) {
        console.log("C100424 item.allDataPresent",item.allDataPresent)
        return null;
      }
      //console.log("E102824 item.categoryy",item.category)
      //console.log("E102824 previousCategory",previousCategory)
      //setPreviousCategory(item.category);
      return (
          <ObservedChartCard  /* ChartCard */
            key={`chart-card-${index}`}
            index={index}
            chartIndicator={item.variables}
            chartIndicatorName={item.breadcrumb}
            allData={allData}
            chartId={chartId}
            height={'200'}
            width={'calc(100% - 0px)'}
            activeGeography={activeGeography}
            colorCategory={item.category}
            /*onSetActiveIndicator={onSetActiveIndicator}
            onSetBivariateIndicator={onSetBivariateIndicator}
            activeIndicator={activeIndicator}
            bivariateIndicator={bivariateIndicator}*/
            options={item}
            _selectedId={selectedId}
            //item={item}
            style={style}
            //persistableSelectedId={persistableSelectedId}
          />
      );
    };
  }, [flattenedModules, allData, chartId, listKey /*activeGeography, selectedId*/]);

  const handleScroll = debounce((scrollOffset: number, scrollDirection:string, scrollUpdateWasRequested:boolean) => {
    setScrollOffset(scrollOffset); // Save the current scroll offset
    //console.log("BB101424 scrollOffset",scrollOffset);
    //console.log("BB101424 scrollDirection",scrollDirection);
    //console.log("BB101424 scrollUpdateWasRequested",scrollUpdateWasRequested);
    //console.log("B101424 scrollOffset",scrollOffset);
    //console.log("BB101424 heightsModules",heightsModules);
    /*const filtered = Object.keys(heightsModules).find(key => {
      const h = heightsModules[key];
      return h.runningHeight > scrollOffset;
    });
    console.log("BB101424 filtered",filtered);
    console.log("BB101424 filtered",heightsModules[filtered ? filtered : moduleId]);*/
    /*if (filtered){
      setScrollIndex(filtered?.runningIndex);
    } */   
    //console.log("E082924 (old) selectedId",selectedId);
    //const itemIndex = Math.floor(scrollOffset / 200); // Calculate item index based on scroll offset and item height
    //console.log("E082924 (new) selectedId itemIndex",itemIndex);
    //updateSelectedId(itemIndex); // fallback logic based on a stanndard height of 200px
    
  }, 30); // Debounce interval set to 300ms

  const getItemSize = (index:number) => {
    //console.log("C082924 index",index);
    //console.log("C082924 flattenedModules[index]",flattenedModules[index]);
    //console.log("A061224 flattenedModules[index].type",flattenedModules[index].type);
    const _object = flattenedModules[index];
    return getItemSizeWithObject(_object);
  }
  
  const getItemSizeWithObject = (_object:any) => {
    if (!_object.allDataPresent) {
      return 0;
    }
    //console.log("D120824 activePage",activePage);
    //if(activePage === "dashboard"){
      return 2000; // temporarily assign for static images
    //}
    let defaultHeight = 250; //300;
    let defaultBarHeight = 200; //300;
    let additional = 16; //300;
    //console.log("A083024 _object",_object);
    switch (_object.type.toLowerCase()) {
      case 'category header':
        return 160;
      case 'histogram':
        return defaultHeight + additional;
      case 'beeswarm':
        return (_object.variables.length * 100) + 100 + additional;
      case 'pie':
        return defaultHeight + additional;
      case 'waffle':
        return defaultHeight + additional;
      //case 'list':
      //  return defaultHeight + additional;
      case 'ranked list':
        return defaultHeight + additional;
      case 'pyramid':
        return ((_object.variables.length / 3) * 25) + 10 + additional; // defaultHeight;
      case 'stacked histograms':
        return (_object.variables.length * 100) + 100 + additional; // defaultHeight;
      case 'stacked swarms':
        return (_object.variables.length * 100) + 100 + additional; // defaultHeight;
      case 'map':
        return 200; // defaultHeight;
      case 'deviation from average':
        return (_object.variables.length * 40) + 70 + additional;
      case 'bar':
        return ((_object.variables.length * 27.5) + 70) < defaultBarHeight ? defaultBarHeight : ((_object.variables.length * 27.5) + 70) + additional;
      default:
        return defaultHeight + additional;
    }
  }
  useEffect(() => {
      console.log("C101424 activeGeography",activeGeography);
      console.log("C101424 flattenedModules",flattenedModules);
      console.log("C101424 |________________ moduleId",moduleId);
      if (listRef.current) {
          //setScrollOffset(listRef.current.state.scrollOffset); // Save the current scroll offset
      }
  }, [activeGeography, flattenedModules]); // Run this effect when the key dependencies change

  useEffect(() => {
    console.log("Z102024 |________________ moduleId", moduleId);
    console.log("Z102024 |________________ moduleIdIncrement", moduleIdIncrement);
    
    // Calculate the new scroll offset based on the moduleId
    let newScrollOffset = heightsModules && heightsModules[moduleId]
        ? heightsModules[moduleId].runningHeight - (vh / 2)
        : 0;

    // If the List component is mounted and the new scroll offset is calculated, scroll to the new position
    if (listRef.current) {
        listRef.current.scrollTo(newScrollOffset);
    }
}, [moduleIdIncrement]); // Depend on moduleIdIncrement to trigger this effect
  useEffect(() => {
    console.log("Z102024 |________________ moduleId", moduleId);
    console.log("Z102024 |________________ moduleIdIncrement", moduleIdIncrement);
    
}, [moduleId]); // Depend on moduleIdIncrement to trigger this effect


  /*useEffect(() => {
      if (listRef.current) {
        console.log("A100524 scrollOffset",scrollOffset);
        let _scrollOffset = scrollOffset;
        if (scrollIndex && scrollOffset && heightsModules){
          console.log("A100524 scrollIndex",scrollIndex);
          console.log("A100524 heightsModules",heightsModules);
          console.log("A100524 heightsModules[scrollIndex]",heightsModules[scrollIndex]);
          _scrollOffset = heightsModules[scrollIndex].runningHeight;
        }
        listRef.current.scrollTo(_scrollOffset); // Restore the saved scroll offset
      }
  }, [listKey]);*/ // Restore scroll after re-render based on the new listKey
  let vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
  console.log("Y102724 moduleId",moduleId);
  console.log("Y102724 heightsModules",heightsModules);
  //console.log("Y102724 heightsModules[moduleId]",heightsModules[moduleId]);
  return (
    <div ref={containerRef} style={{ height: '100%' }}>
      <List
        className='list-container'
        key={`list_${listKey}`} // Adding a key to force re-render when flattenedModules changes
        //key={`list_${listKey}_${moduleIdIncrement}`} // Adding a key to force re-render when flattenedModules changes
        height={containerHeight}
        width="100%"
        itemCount={flattenedModules ? flattenedModules.length : 0}
        itemSize={getItemSize} // The height of each row in pixels
        style={{ overflowX: 'hidden' }}
        onScroll={({ scrollOffset,scrollDirection, scrollUpdateWasRequested }) => handleScroll(scrollOffset, scrollDirection, scrollUpdateWasRequested)}
        ref={listRef}
        initialScrollOffset={heightsModules 
            ? heightsModules[moduleId] 
                ? heightsModules[moduleId].runningHeight - (vh / 2)
                : 0
            :0}
      >
        {renderRow}
      </List>
    </div>
  );
};

export default ChartPage;
