import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ExportToImage } from '../utilities/exports/image/ExportToImage';
import { ExportToExcel } from '../utilities/exports/xlsx/ExportToExcel';
import { onHoverHistogram, useGlobalHoveredId, useGlobalSelectedId, useGlobalActiveGeography, useGlobalActiveIndicator, useGlobalBivariateIndicator } from '../../data/StatusStore';
import XShareButton from './XShareButton';
import { getGeoName } from '../utilities/Utilities';

interface GeoChangeModalProps {
  isVisible: boolean;
  onClose: () => void;
  handleChangeWhenUnavailable: (geo:string) => void;
  category: string;
}

// Use shouldForwardProp to prevent isVisible from being passed to the DOM
const ModalBackdrop = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'isVisible',
})<{ isVisible: boolean }>`
  display: ${(props) => (props.isVisible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999; /* High z-index */
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 50vh;
  left: calc(50vw - 90px)  ;
  transform: translate(0%, -50%);
  background-color: #D3D3D3; /* Gray background */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  padding: 20px;
  max-width: 730px;
  height: 473px;
  z-index: 10000; /* Ensure it is above the backdrop */
  border: 1px solid #231f20;
`;

const ModalHeader = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalTitle = styled.h2`
  margin: 0;
  font-size: 20px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
`;

const ModalContent = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 285px;
  gap: 10px;
`;

const ActionButton = styled.div`
  padding: 10px 15px;
  margin-left:10px;
  border: none;
  border-radius: 0px;
  background-color: none;
  border: 1px solid #231f20;
  color: #231f20;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  align-content: center;
  width: 124px;
  min-height: 34px;
  &:hover {
    color: #a29f9f;
    border: 1px solid #a29f9f;
  }
`;

const GeoChangeModal: React.FC<GeoChangeModalProps> = ({ isVisible, onClose, category, handleChangeWhenUnavailable  }) => {

  const activeGeography = useGlobalActiveGeography();
  const selectedId = useGlobalSelectedId();

  const handleCancel = () => {
    onClose();
  };

  const handleShow = (e: React.ChangeEvent<HTMLInputElement>, category:string) => {
    console.log("A121124 handleShow category", category);
    handleChangeWhenUnavailable(category);
    onClose();
  };

  return (
    <ModalBackdrop isVisible={isVisible} onClick={onClose}>
      <ModalContainer onClick={(e) => e.stopPropagation()}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '0px',
          }}
        >
          <ModalHeader>
            <ModalTitle>Change Geography?</ModalTitle>
          </ModalHeader>
          <CloseButton onClick={onClose}>&times;</CloseButton>
        </div>
        <ModalContent>
            <div
            style={{
              width: '100%',
              display: "flex",
              alignContent: "space-between",
            }}
            >  
            <div
              style={{
              width: 'calc(100% - 236px)',
              alignContent: 'center',
              paddingLeft: '0px',
              }}
            >
              {`We don't have data for this indicator at for ${getGeoName(category)} (${category}s). Are you sure you want to use ${category} as your geography? If so we will change geographies and start from the top of the scroll.`}</div>
            </div>
        </ModalContent>
        <ModalFooter>
          <div
            style={{
              marginLeft: '-10px',
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <ActionButton 
              onClick={handleCancel} 
              style={{ 
              color: '#231f20',
              cursor: 'pointer',
              border: '1px solid #231f20',
              }}
              onMouseEnter={(e) => {
                (e.target as HTMLElement).style.color = '#a29f9f';
              }}
              onMouseLeave={(e) => {
                (e.target as HTMLElement).style.color = '#231f20';
              }}
            >
              Cancel
            </ActionButton>
            <ActionButton 
              onClick={(e: any) => { handleShow(e, category)}}
              style={{ 
              color: '#231f20',
              cursor: 'pointer',
              border: '1px solid #231f20',
              }}
              onMouseEnter={(e) => {
                (e.target as HTMLElement).style.color = '#a29f9f';
              }}
              onMouseLeave={(e) => {
                (e.target as HTMLElement).style.color = '#231f20';
              }}
            >
              {`Show ${category}s`}
            </ActionButton>
          </div>
        </ModalFooter>
      </ModalContainer>
    </ModalBackdrop>
  );
};

export default GeoChangeModal;
