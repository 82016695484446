import React, { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import Colors from '../utilities/Colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { onSetActiveGeography, useGlobalHoveredId, 
  useGlobalSelectedId, useGlobalActiveGeography } from '../../data/StatusStore';
import { useGlobalSwarmCalibration } from '../../data/GlobalStore';
import { d2gRound } from '../utilities/Utilities';

interface BaseDataItem {
  id: string;
  value: number;
}

interface DodgeDataItem extends BaseDataItem {
  x: number;
  y: number;
  next: DodgeDataItem | null;
}

interface SwarmChartDynamicProps {
  dataArray: BaseDataItem[];
  histMax: number;
  histMin: number;
  histNA: number;
  histLength: number;
  chartId: string;
  nodes: DataItem[];
  DESCRIPTOR: string;
  labelOnly?: any;
}

interface DataItem {
  id: string;
  value: number;
  x?: number;
  y?: number;
  tooltip?: any;
}

const SwarmDynamic: React.FC<SwarmChartDynamicProps> = ({
  dataArray,
  chartId,
  histMax,
  histMin,
  histNA,
  histLength,
  nodes,
  DESCRIPTOR,
  labelOnly=null,
}) => {
  const width = "100%";
  const height = "100%";
  const svgRef = useRef<SVGSVGElement>(null);
  const [containerWidth, setContainerWidth] = useState<number>(0);
  /*const radius = dataArray 
    ? dataArray.length > 100 
      ? 1 
      : 4
    :1; // Radius of the circles on the plot
  
  const padding = dataArray
    ? dataArray.length > 100 
      ? .25 
      : 1
    :.25; // Padding between circles on the plot*/
  //console.log("B112624 labelOnly", labelOnly);
  const swarmCalibration = useGlobalSwarmCalibration(); 
  const hoveredId = useGlobalHoveredId();
  const selectedId = useGlobalSelectedId();
  const activeGeography = useGlobalActiveGeography();

  const [padding, setPadding] = useState<number>(swarmCalibration[activeGeography].padding);
  const [radius, setRadius] = useState<number>(swarmCalibration[activeGeography].radius);
  const adjustment = 0;//35;

  useEffect(() => {
    const updateContainerWidth = () => {
      if (svgRef.current) {
        setContainerWidth(svgRef.current.getBoundingClientRect().width);
      }
    };

    window.addEventListener('swarn_resize', updateContainerWidth);
    updateContainerWidth(); // Initial update

    return () => window.removeEventListener('swarm_resize', updateContainerWidth);
  }, [hoveredId]);

  return (  <>
    {nodes.map((node, i) => {   
      const isSelected = node.id == selectedId;
      const isHovered = node.id == hoveredId;
      const fill = isSelected ? Colors.highlightSelect : isHovered ? Colors.highlightHover : 'none'; // 'none' for transparent or any default non-highlight color
      
      if (!isSelected && !isHovered) return null; // Skip rendering if not selected or hovered
      const color = Colors.highlightHover; //getColorQuintile(i, histNA, histLength);
      //console.log("050924 containerWidth, nodes.length", containerWidth, nodes.length)
      return (
        <div key={`cont_dyn_${i}_${chartId}`}><div 
        key={`swarm_${isSelected ? 'selected' : 'hovered'}_${chartId}_${i}`}
        className='swarm-container swarm-container-dynamic' 
        style={{
          position: "absolute",
          top: "-40px",
          width:"100%",
          //marginLeft: "10px",
          //marginRight: "10px",
        }}>
            <div
              className={`hist-label-rect ${isSelected ? 'popup-selected' : 'popup-hovered'}`}
              key={`swarm_${isSelected ? 'selected' : 'hovered'}_${chartId}_${i}_box`}
              style={{
                width:"80px",
                height:"50px",
                backgroundColor: "#FAF5F5",
                border: `1px solid ${fill}`,
                position: "absolute",
                fontSize: "12px",
                textAlign: "center",
                //left:node.x ? `calc(${node.x}% - (30px + ${node.x/100 * (activeGeography == "Tract" ? 23 : 18)}px + ${activeGeography == "Tract" ? 0 : radius}px))`: "0", //`${(i * (containerWidth / nodes.length)) - 50}px`,
                //left:node.x ? `calc(${node.x}% - (30px + ${node.x/100 * (activeGeography == "Tract" ? 23 : 18)}px + ${activeGeography == "Tract" ? 0 : radius}px - ${activeGeography == "Boro" ? radius : 0}px))`: "0", 
                left:node.x ? `calc(${node.x}% - (30px + ${node.x/100 * (activeGeography == "Tract" ? 23 : 18)}px))`: "0", 
                lineHeight: 1.25,
                paddingTop: activeGeography === "Tract" ? "0px" : "8.5px",
                color: `${fill}`,
              }}
            >
              <div
              key={`b_svg_swarm_${isSelected ? 'selected' : 'hovered'}_${chartId}_${i}`}
              style={{
                  zIndex: 10,
                  position:"relative",
                  fontSize: node.tooltip ? "10px" : "12px",
              }}
              >
                {`${activeGeography} ${node.id}`}<br/><b>{`${d2gRound(node.value, { DESCRIPTOR: DESCRIPTOR, rateTotal: true })}`}<br/>{node.tooltip ? `(${d2gRound(node.tooltip, { DESCRIPTOR: labelOnly[0].sort.DESCRIPTOR, rateTotal:true })})` : ""}</b></div>
                <div
                  key={`a_svg_swarm_${isSelected ? 'selected' : 'hovered'}_${chartId}_${i}`}
                  style={{
                    width:"16px",
                    height:"16px",
                    backgroundColor: "#FAF5F5",
                    border: `1px solid ${fill}`,
                    position: "absolute",
                    bottom:"-8.5px",
                    left:"calc(50% - 8px)",
                    rotate: "45deg",
                    borderTop:"none",
                    borderLeft:"none",
                    zIndex: 9,
                  }}
                ></div>
            </div>
          </div>
          <div 
          key={`svg_swarm_${isSelected ? 'selected' : 'hovered'}_${chartId}_${i}`}
          className='swarm-container swarm-container-dynamic' 
          style={{
            position: "absolute",
            top: "0px",
            width:"100%",
            //marginLeft: "10px",
            //marginRight: "10px",
          }}>
          <svg 
          key={`svg_svg_swarm_${isSelected ? 'selected' : 'hovered'}_${chartId}_${i}`}
          ref={svgRef} width={width} height={height} className="swarm-chart-dynamic">
                <circle
                  key={node.id}
                  cx={node.x 
                    ? `${node.x}%` 
                    : `${radius}px`}
                  //cx={node.value ? `${(((node.value - histMin) / (histMax - histMin)) * 100)}%` : 0}
                  cy={node.y 
                    ? `${((parseInt(height) / 2 - radius - padding + adjustment - node.y) / parseInt(height)) * 100}%`
                    : `${((parseInt(height) / 2 - radius - padding + adjustment) / parseInt(height)) * 100}%`} // Adjust y position based on radius and padding
                  //cy={parseInt(height) / 2 - radius - padding - node.y} // Adjust y position based on radius and padding
                  //cy={`${node.y / 2}%)`} // Adjust y position based on radius and padding
                  //cy={`calc(${parseInt(height) / 2 } - ${node.y}%)`} // Adjust y position based on radius and padding
                  r={radius}
                  fill={fill}
                />

          </svg>
        </div>
        </div>
      )})}
      </>);
};

export default SwarmDynamic;
