import React, { useState, useEffect } from 'react';
import SwarmStatic from './SwarmStatic';
import SwarmDynamic from './SwarmDynamic';

export interface DataItem {
  id: string;
  value: number;
  //group: number; // This could be used for categorizing data into swarms
}

export interface SwarmChartProps {
    dataArray: DataItem[];
    histMax: number;
    histMin: number;
    histNA: number;
    histLength: number; 
    chartId: string;
    indicator: any;
    data:any;
    colorClass:string;
    labelOnly?: any;
    options?: any;
}

const Swarm: React.FC<SwarmChartProps> = ({ dataArray, histMax, histMin, histNA, histLength, chartId, indicator, data, colorClass, labelOnly=null, options=null }) => {
  
  const [nodes, setNodes] = useState<DataItem[]>([]);
  //console.log("DD112624 indicator", indicator);
  //console.log("D112624 dataArray", dataArray);
  //console.log("D112624 data", data);
  //console.log("D112624 nodes", nodes);
  
  return (
    indicator
      ? <div className="module-parent">
          <div className="module-child-2-chart">
                <SwarmStatic 
                  key={`static_${indicator.variable}`}
                  dataArray={dataArray} 
                  histMax={histMax}
                  histMin={histMin}
                  histNA={histNA} 
                  histLength={histLength} 
                  chartId={chartId}
                  nodes={nodes}
                  setNodes={setNodes}
                  indicator={indicator.variable}
                  colorClass={colorClass}
                  DESCRIPTOR={data.meta["DESCRIPTOR"][indicator.variable]}
                  labelOnly={labelOnly}
                  allData={data}
                  options={options}
                />
                <SwarmDynamic 
                  key={`dynamic_${indicator.variable}`}
                  dataArray={dataArray} 
                  histMax={histMax}
                  histMin={histMin}
                  histNA={histNA} 
                  histLength={histLength} 
                  chartId={chartId}
                  nodes={nodes}
                  DESCRIPTOR={data.meta["DESCRIPTOR"][indicator.variable]}
                  labelOnly={labelOnly}
              />
          </div>
      </div>
      : null);
};

export default Swarm;
