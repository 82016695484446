import React, { useState, useEffect, useRef } from 'react';
import { useGlobalSelectedId, onSetActiveIndicator, onSetActiveDBModuleId, onSetActiveModuleId, useGlobalActivePage } from '../../data/StatusStore';
import { d2gRound } from '../utilities/Utilities';
import Colors from '../utilities/Colors';

export interface DeviationFromAverageChartProps {
  options: any;
  data: any;
  width: number;
  height: number;
  colorClass: string;
}

const MARGIN_X = 10; // Added padding to prevent elements from touching the edges
const MARGIN_Y = 10;
const FIXED_BAR_WIDTH = 200; // Fixed width for testing

const DeviationFromAverage: React.FC<DeviationFromAverageChartProps> = ({ options, data, width, height, colorClass }) => {
  const [preppedData, setPreppedData] = useState<any>([]);
  const [barHeight, setBarHeight] = useState<number>(40); // Reset bar height
  const [spaceHeight, setSpaceHeight] = useState<number>(10); // Reset space between bars for alignment
  const selectedId = useGlobalSelectedId();
  const activePage = useGlobalActivePage();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [dimensions, setDimensions] = useState<{ width: number; height: number }>({ width, height });
  const [maxDeviation, setMaxDeviation] = useState<number>(0);
  const [minDeviation, setMinDeviation] = useState<number>(0);
  const [stdDeviations, setStdDeviations] = useState<{ [key: string]: number }>({}); // Store standard deviations

  useEffect(() => {
    if (containerRef.current) {
      const resizeObserver = new ResizeObserver(entries => {
        if (!entries || entries.length === 0) {
          return;
        }
        const { width, height } = entries[0].contentRect;
        setDimensions({ width, height });
      });

      resizeObserver.observe(containerRef.current);

      return () => {
        if (containerRef.current) {
          resizeObserver.unobserve(containerRef.current);
        }
      };
    }
  }, []);

  useEffect(() => {
    // Preprocess data whenever `selectedId` changes
    if (selectedId) {
      prepData();
    }
  }, [selectedId]);

  const calculateStandardDeviation = (values: number[]) => {
    const mean = values.reduce((a, b) => a + b, 0) / values.length;
    return Math.sqrt(values.reduce((sum, val) => sum + Math.pow(val - mean, 2), 0) / values.length);
  };

  const prepData = () => {
    if (selectedId) {
      const _filteredData = options.variables.filter((v: any) => !v.sort.Tooltip_only);
      console.log("B103024 data", data);
      const _preppedData = _filteredData.map((v: any) => {
        const deviation = data.meta.avg[v.variable] - data.dataJSON[selectedId][v.variable] || 0;
        return {
          name: v.label,
          deviation,
          value: data.dataJSON[selectedId][v.variable],
          average: data.meta.avg[v.variable],
          max: data.meta.max_value[v.variable],
          min: data.meta.min_value[v.variable],
          variable: v.variable,
          //index: keyIndexDataArray[feature.properties.GEOID20].index, 
          //na: data.meta.na_count[v.variable],
          //len: dataArray.length
        };
      });

      setPreppedData(_preppedData);
      console.log("Prepared Data:", _preppedData); // Log prepped data for inspection

      // Calculate standard deviations and max/min deviations for entire dataset
      const _stdDeviations: { [key: string]: number } = {};
      let globalMaxDeviation = 0;
      let globalMinDeviation = Number.POSITIVE_INFINITY;

      _filteredData.forEach((v: any) => {
        const values = Object.values(data.dataJSON).map((d: any) => d[v.variable]);
        _stdDeviations[v.variable] = calculateStandardDeviation(values);

        // Find max/min deviations in the dataset for consistent bar scale
        const deviations = values.map(value => Math.abs(data.meta.avg[v.variable] - value));
        globalMaxDeviation = Math.max(globalMaxDeviation, ...deviations);
        globalMinDeviation = Math.min(globalMinDeviation, ...deviations);
      });

      setStdDeviations(_stdDeviations);
      setMaxDeviation(globalMaxDeviation || 1); // Ensure maxDeviation has a fallback value
      setMinDeviation(globalMinDeviation || 0);
      console.log("Max Deviation:", globalMaxDeviation); // Log max deviation for inspection
    }
  };

  const barScale = (value: number) => {
    console.log("Scaling Value:", value); // Log scaling value to ensure non-zero values
    return (value / maxDeviation) * FIXED_BAR_WIDTH; // Fixed width for testing visibility
  };

  const onClicked = (variable: string) => {
    if (activePage === "explorer") {
      onSetActiveModuleId(options.variables[0].sort.moduleId);
    }else{
      onSetActiveDBModuleId(options.variables[0].sort.moduleId);
    }
    onSetActiveIndicator(variable);
  };

  const fontSize = 12;
  const fontSize2 = 12;
  const labelWidth = "305px";
  const chartWidth = `calc(100% - 305px)`;

  // Calculate fixed positions for +1 SD and -1 SD lines across all bars
  const sdLinePosRight = barScale(Math.max(...Object.values(stdDeviations)));
  const sdLinePosLeft = -sdLinePosRight;

  return (
    <div ref={containerRef} style={{ width: '100%', height: '100%', position: 'relative', display: 'flex', alignItems: 'flex-start', paddingRight: "16px" }}>
      <div style={{ marginLeft: '0px', marginRight: '0px', fontSize: fontSize2, lineHeight: `${fontSize2 + 2}px`, width: labelWidth }}>
        {preppedData.map((d: any, i: number) => (
          <div key={`info-${i}`} style={{ fontSize: fontSize2, lineHeight: `${fontSize2 + 2}px`, display: "inline-flex", justifyContent: "space-between", height: `${barHeight}px` }}>
            <div style={{ fontWeight: 'bold', width: "75px", alignContent: "center" }}>{d2gRound(d.value, { DESCRIPTOR: data.meta["DESCRIPTOR"][d.variable] })}</div>
            <div style={{ fontWeight: '500', width: "calc(100% - 75px)", alignContent: "center", paddingRight: "15px" }}>{d.name.split("(")[0]}</div>
          </div>
        ))}
      </div>
      <div style={{ fontSize, lineHeight: `${fontSize + 2}px`, display: 'flex', width: chartWidth, position: "relative" }}>
        <svg width={"100%"} height={barHeight * preppedData.length + 20} style={{ display: "inline-block" }}>
          <g transform={`translate(${MARGIN_X + barScale(Math.abs(maxDeviation))}, 0)`}>
            {/* Render standard deviation lines consistently for all bars */}
            <line
              x1={sdLinePosRight}
              y1={0}
              x2={sdLinePosRight}
              y2={barHeight * preppedData.length}
              stroke='#9E9C9C'
              strokeWidth={0.5}
              strokeDasharray="4"
            />
            <text
              x={sdLinePosRight + 5}
              y={10}
              fill='#9E9C9C'
              fontSize="10px"
            >
              +1 SD
            </text>
            <line
              x1={sdLinePosLeft}
              y1={0}
              x2={sdLinePosLeft}
              y2={barHeight * preppedData.length}
              stroke='#9E9C9C'
              strokeWidth={0.5}
              strokeDasharray="4"
            />
            <text
              x={sdLinePosLeft + 5}
              y={10}
              fill='#9E9C9C'
              fontSize="10px"
            >
              -1 SD
            </text>

            {preppedData.map((d: any, i: number) => (
              <g key={`deviation-${i}`} transform={`translate(0, ${i * barHeight})`}>
                <rect
                  x={d.value >= d.average ? 0 : -barScale(d.deviation)}
                  y={0}
                  width={barScale(Math.abs(d.deviation))}
                  height={barHeight - spaceHeight}
                  fill={Colors.getColorQuintile(d.value, d.min, d.max, colorClass)}
                  onClick={() => onClicked(d.variable)}
                  style={{ cursor: 'pointer' }}
                />
              </g>
            ))}

            {/* Draw central line for zero */}
            <line
              x1={0}
              y1={0}
              x2={0}
              y2={barHeight * preppedData.length}
              stroke="black"
            />
            <text
              x={0}
              y={barHeight * preppedData.length + 10}
              fill="black"
              fontSize="12px"
              textAnchor="middle"
            >
              Avg
            </text>
          </g>
        </svg>
      </div>
    </div>
  );
};

export default DeviationFromAverage;
