import React, { useState, useEffect, useMemo, useRef } from 'react';
import * as d3 from "d3";
import { useGlobalSelectedId, onSetActiveIndicator, onSetActiveDBModuleId, onSetActiveModuleId, useGlobalActivePage } from '../../data/StatusStore';
import { d2gRound } from '../utilities/Utilities';
import Colors from '../utilities/Colors';
import PieMeta from './PieMeta';

export interface PieChartProps {
  options: any;
  data: any;
  width: number;
  height: number;
  colorClass: string;
}

const colors = Colors.wafflePieColors;

interface PathElement extends SVGPathElement {
  _current?: d3.PieArcDatum<any>;
}

const Pie: React.FC<PieChartProps> = ({ options, data, width, height, colorClass }) => {
  const [preppedData, setPreppedData] = useState<any>([]);
  const [hoveredIndicator, setHoveredIndicator] = useState<any>(null);
  const [tooltip, setTooltip] = useState<{ x: number; y: number; obj: any } | null>(null); // Tooltip state
  const selectedId = useGlobalSelectedId();
  const activePage = useGlobalActivePage();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const svgRef = useRef<SVGSVGElement | null>(null);
  const [dimensions, setDimensions] = useState<{ width: number; height: number }>({ width, height });

  const fontSize = 12;
  const fontSize2 = 12; // Define secondary font size

  useEffect(() => {
    if (containerRef.current) {
      const resizeObserver = new ResizeObserver(entries => {
        if (!entries || entries.length === 0) return;
        const { width, height } = entries[0].contentRect;
        setDimensions({ width, height });
      });

      resizeObserver.observe(containerRef.current);

      return () => {
        if (containerRef.current) {
          resizeObserver.unobserve(containerRef.current);
        }
      };
    }
  }, []);

  useEffect(() => {
    if (selectedId) {
      prepData();
    }
  }, [selectedId]);

  const prepData = () => {
    if (selectedId) {
      const _filteredData = options.variables.filter((v: any) => v.sort.Tooltip_only === "");
      const _tooltipData = options.variables.filter((v: any) => v.sort.Tooltip_only !== "");

      const _preppedData = _filteredData.map((v: any) => {
        const ttVariable = _tooltipData.find((t: any) => t.sort.Tooltip_only === v.variable);
        const tooltip = ttVariable ? data.dataJSON[selectedId][ttVariable.variable] : null;
        const value = data.dataJSON[selectedId][v.variable]
        const presentableTooltip = tooltip 
          ? d2gRound(tooltip, { DESCRIPTOR: data.meta["DESCRIPTOR"][ttVariable] }) 
          : d2gRound(value, { DESCRIPTOR: data.meta["DESCRIPTOR"][v.variable] }) 

        console.log("111824 presentableTooltip", presentableTooltip)
        console.log("111824 data.meta[DISPLAY_NAME]",  data.meta["DISPLAY_NAME"])
        console.log("111824 ttVariable",  ttVariable)
        const presentableLabel = tooltip 
          ? data.meta["DISPLAY_NAME"][ttVariable.variable] ? data.meta["DISPLAY_NAME"][ttVariable.variable].split("(")[0] : ""
          : data.meta["DISPLAY_NAME"][v.variable] ? data.meta["DISPLAY_NAME"][v.variable].split("(")[0] : ""
        
        return {
          name: v.label,
          value: data.dataJSON[selectedId][v.variable],
          variable: v.variable,
          tooltip: presentableTooltip,
          tooltipLabel: presentableLabel,
        };
      });

      setPreppedData(_preppedData);
    }
  };

  const total = preppedData.reduce((acc: number, d: any) => acc + d.value, 0); // Calculate total
  const chartSize = Math.min(dimensions.width / 2, dimensions.height / 2);
  const radius = chartSize / 2;

  const pie = useMemo(() => {
    const pieGenerator = d3.pie<any>().value((d) => d.value);
    return pieGenerator(preppedData);
  }, [preppedData]);

  const arcGenerator = d3.arc<d3.PieArcDatum<any>>()
    .innerRadius(0)
    .outerRadius(radius + 5);

  const onClicked = (variable: any) => {
    // Existing click functionality
    if (activePage === "explorer") {
      onSetActiveModuleId(options.variables[0].sort.moduleId);
    }else{
      onSetActiveDBModuleId(options.variables[0].sort.moduleId);
    }
    onSetActiveIndicator(variable);

    // Set hovered indicator on click
    options.variables.forEach((v: any) => {
      if (v.variable === variable) {
        setHoveredIndicator(v);
      }
    });
  };

  useEffect(() => {
    if (!svgRef.current) return;

    const svg = d3.select(svgRef.current);
    const g = svg.select('g.container');

    const paths = g.selectAll<PathElement, d3.PieArcDatum<any>>('path').data(pie);

    paths.enter()
      .append<PathElement>('path')
      .attr('fill', (d, i) => colors[i % colors.length])
      .each(function (d) {
        const thisPath = this as PathElement;
        thisPath._current = d; // Store initial state for tweening
      })
      .attr('d', arcGenerator)
      .style('cursor', 'pointer')
      .on('click', (event, d) => onClicked(d.data.variable)) // Fixed function call
      .on('mouseenter', (event, d) => handleMouseEnter(event, d.data))
      .on('mousemove', handleMouseMove)
      .on('mouseleave', handleMouseLeave);

    paths.transition()
      .duration(750)
      .attrTween('d', function (d) {
        const thisPath = this as PathElement;
        const interpolate = d3.interpolate(thisPath._current, d);
        thisPath._current = interpolate(1);
        return (t) => arcGenerator(interpolate(t)) || '';
      });

    paths.exit().remove();
  }, [pie, radius]);

  const handleMouseEnter = (event: React.MouseEvent<SVGPathElement>, obj: any) => {
    const rect = containerRef.current?.getBoundingClientRect();
    const { clientX, clientY } = event;
    setTooltip({
      x: clientX - (rect?.left || 0),
      y: clientY - (rect?.top || 0),
      obj,
    });
  };

  const handleMouseMove = (event: React.MouseEvent<SVGPathElement>) => {
    const rect = containerRef.current?.getBoundingClientRect();
    const { clientX, clientY } = event;
    setTooltip((prev) => prev && {
      ...prev,
      x: clientX - (rect?.left || 0),
      y: clientY - (rect?.top || 0),
    });
  };

  const handleMouseLeave = () => {
    setTooltip(null); // Hide tooltip on mouse leave
  };

  return (
    <div ref={containerRef} style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'flex-start', paddingRight: "16px" }}>
      <div
        style={{
          marginLeft: '0px',
          marginRight: '0px',
          fontSize: fontSize2,
          lineHeight: `${fontSize2 + 2}px`,
          width: '250px',
        }}
      >
        <PieMeta
          dataArray={data.dataArray[hoveredIndicator ? hoveredIndicator : options.variables[0]]}
          data={data}
          indicator={hoveredIndicator ? hoveredIndicator : options.variables[0]}
        />
      </div>
      <div
        style={{
          marginRight: '0px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <svg
          ref={svgRef}
          width={radius * 2 + 10}
          height={radius * 2 + 10}
          style={{
            display: "inline-block",
          }}
        >
          <g transform={`translate(${radius + 5}, ${radius + 5})`} className="container" />
        </svg>
      </div>
      <div
        style={{
          marginLeft: '0px',
          fontSize,
          lineHeight: `${fontSize + 2}px`,
          display: 'flex',
          flexDirection: 'column',
          width: `calc(100% - ${((radius * 2) + 10)}px - 250px)`,
          paddingTop: '20px',
        }}
      >
        {preppedData.map((d: any, i: number) => (
          <div key={i} style={{ marginBottom: '5px', cursor: 'pointer', display: 'flex', paddingBottom: '12px' }} onClick={() => onClicked(d.variable)}>
            <div style={{ width: '120px', fontWeight: 'bold', color: Colors.black, textAlign: 'left', paddingLeft: '5px' }}>
              {d2gRound(d.value, { DESCRIPTOR: data.meta["DESCRIPTOR"][d.variable] })}
            </div>
            <div 
              style={{ 
                width: '100%', 
                color: Colors.black, 
                textAlign: 'left', 
                whiteSpace: 'normal', 
                overflowWrap: 'break-word', 
                paddingLeft: "5px",
                textDecoration: `underline ${Colors.wafflePieColors[i]} 2px`,
              }}>
              {d.name}
            </div>
          </div>
        ))}
      </div>
      {tooltip && (
        <div
          style={{
            position: 'absolute',
            top: tooltip.y - 20,
            left: tooltip.x - 42.5,
            backgroundColor: '#fff',
            border: '2px solid #ccc',
            borderRadius: '6px',
            padding: '8px 12px',
            pointerEvents: 'none',
            whiteSpace: 'normal',
            maxWidth: '150px',
            width:'150px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
            fontSize: '12px',
            color: '#333',
            zIndex: 10,
            height:'50px',
            textAlign: 'left',
            wordBreak: 'break-word', // Ensures long words or strings wrap correctly              
            lineHeight: '0.9',
          }}
        >
          <b>{tooltip.obj.tooltip}</b> <span style={{ fontSize: '10px'}}>{tooltip.obj.tooltipLabel}</span>
          <div
            style={{
              position: 'absolute',
              bottom: '-7px',
              left: '45%',
              width: '12px',
              height: '12px',
              backgroundColor: '#fff',
              borderLeft: '2px solid #ccc',
              borderBottom: '2px solid #ccc',
              transform: 'rotate(-45deg)',
              boxShadow: '-1px 1px 2px rgba(0, 0, 0, 0.1)',
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Pie;
