import React, { useEffect, useState } from 'react';
import { useMap, useMapEvent } from 'react-leaflet';
import L from 'leaflet';
import { onSetActiveGeography, useGlobalActiveGeography, /*onSetCachedActiveIndicators,  onSetCachedSelectedIds, useGlobalCachedActiveIndicators, useGlobalCachedSelectedIds, onSetActiveIndicator, onSetBivariateIndicator*/ } from '../../data/StatusStore';

interface MapZoomControlProps {
  onClickButton: () => void;
}

const MapZoomControl: React.FC<MapZoomControlProps> = ({ onClickButton }) => {
  const map = useMap();
  const activeGeography = useGlobalActiveGeography();
  //const cachedActiveIndicators = useGlobalCachedActiveIndicators();
  //const cachedSelectedIds = useGlobalCachedSelectedIds();
  const [selectedValue, setSelectedValue] = useState<string>(activeGeography);

  useEffect(() => {
    // Create a Leaflet Control to position the radio buttons on the map
    const controlContainer = L.DomUtil.create('div', 'leaflet-control leaflet-bar');
    const customControl = new L.Control({ position: 'topright' }); // You can adjust the position

    customControl.onAdd = function() {
      L.DomEvent.disableClickPropagation(controlContainer);
      return controlContainer;
    };

    customControl.addTo(map);

    return () => {
      customControl.remove();
    };
  }, [map]);

  useMapEvent('click', () => {
    // Optional: Respond to map clicks or other events
  });

  return (
    <div className="leaflet-control-container">
      <div className="leaflet-top leaflet-right">
        <div className="leaflet-control leaflet-control-custom"
          id='mapResetZoom'
          style={{
            paddingBottom: "3px",
            paddingTop: "3px",
            paddingRight: "10px",
            paddingLeft: "10px",
            cursor: "pointer",
          }}
          onClick={onClickButton}
        >
            reset zoom
        </div>
      </div>
    </div>
  );
};

export default MapZoomControl;
